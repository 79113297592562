import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';

interface IPayments {
  ENABLE_PAYMENT: number;
  ENABLE_CREDIT_CARD: number;
  ENABLE_CREDIT_CARD_WITHDRAW: number;
  ENABLE_WEB_TRADER: number | null;
  PAYMENT_CURRENCY: string[];
  PAYMENT_CURRENCY_KASHA: string[];
  PAYMENT_GATEWAYS: { value: string; label: string }[];
  TRANSLATE: Record<string, string>;
  // captcha
  ENABLE_RECAPTCHA: 1 | 0;
  RECAPTCHA_KEY: string;

  WEBTRADER_URL: string | null;
}

const initialState: IPayments = {
  ENABLE_PAYMENT: 0,
  ENABLE_CREDIT_CARD: 0,
  ENABLE_CREDIT_CARD_WITHDRAW: 0,
  ENABLE_WEB_TRADER: null,
  PAYMENT_CURRENCY: [],
  PAYMENT_CURRENCY_KASHA: [],
  PAYMENT_GATEWAYS: [],
  TRANSLATE: {},
  // captcha
  ENABLE_RECAPTCHA: 0,
  RECAPTCHA_KEY: '',

  WEBTRADER_URL: null,
};

const paymentsCurrency = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setPaymentsParameters(state, action) {
      state.ENABLE_PAYMENT = action.payload.ENABLE_PAYMENT;
      state.ENABLE_CREDIT_CARD = action.payload.ENABLE_CREDIT_CARD;
      state.ENABLE_CREDIT_CARD_WITHDRAW = action.payload.ENABLE_CREDIT_CARD_WITHDRAW;
      state.PAYMENT_CURRENCY = action.payload.PAYMENT_CURRENCY;
      state.PAYMENT_GATEWAYS = action.payload.PAYMENT_GATEWAYS;
      state.ENABLE_WEB_TRADER = action.payload.ENABLE_WEB_TRADER;
      state.TRANSLATE = action.payload.TRANSLATE;
      // captcha
      state.ENABLE_RECAPTCHA = action.payload.ENABLE_RECAPTCHA;
      state.RECAPTCHA_KEY = action.payload.RECAPTCHA_KEY;

      state.WEBTRADER_URL = action.payload.WEBTRADER_URL;
    },
    setPaymentsKashaCurrency(state, action) {
      state.PAYMENT_CURRENCY_KASHA = action.payload.PAYMENT_CURRENCY;
    },
    // setPaymentsMandatoCurrency(state, action) {
    //   state.PAYMENT_CURRENCY = action.payload.PAYMENT_CURRENCY
    // }
  },
});

const selectHasRecaptcha = (state: RootState) => state.payments.ENABLE_RECAPTCHA === 1;
const selectRecaptchaKey = (state: RootState) => state.payments.RECAPTCHA_KEY;
const selectWTUrl = (state: RootState) => state.payments.WEBTRADER_URL;

export { selectHasRecaptcha, selectRecaptchaKey, selectWTUrl };

export const { setPaymentsParameters, setPaymentsKashaCurrency } = paymentsCurrency.actions;
export default paymentsCurrency.reducer;
