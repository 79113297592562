import React, { FC, useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  NavigateFunction,
  Location,
} from 'react-router-dom';
import { ROUTES, PRIVATE_ROUTES } from './routes/routes';
import ChatPage from './pages/Chat/Chat';
import LangWrapper from './components/LangWrapper';
import { getHeaderInfo } from './api/myProfile';
import { setHeaderInfo } from './store/slice/headerInfoSlice';
import { appendTheme } from './utils/theme';
import { getStyleListMethod } from './api/settings';
import { useAppDispatch, useAppSelector } from './store';
import { getAuthorized, getPaymentsEnablePayment } from './utils/selectors';
import {
  setForgotPasswordImage,
  setLogoCompany,
  setSignInImage,
  setSignUpImage,
} from './store/slice/settingsCompanySlice';
import { currencyKashaPayments, getPaymentsInfo } from './api/payments-currency';
import { setPaymentsKashaCurrency, setPaymentsParameters } from './store/slice/paymentsCurrency';
import { useClearCache } from 'react-clear-cache';
import { useCheckIpCountry } from 'hooks/useCheckIpCountry';
import { isChatEnabled } from './pages/Chat/helpers/isChatEnabled';
import { SENTRY_INIT_CONFIG } from './sentry-init.config';
import * as Sentry from '@sentry/react';

Sentry.init(SENTRY_INIT_CONFIG);

const App: FC = () => {
  const auth: boolean = useAppSelector(getAuthorized);
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useAppDispatch();
  const ENABLE_PAYMENT = useAppSelector(getPaymentsEnablePayment);
  const CURRENT_PRIVATE_ROUTES = ENABLE_PAYMENT
    ? PRIVATE_ROUTES
    : PRIVATE_ROUTES.filter((item) => item.path !== '/wire-statuses');
  const checkRoute = (): void => {
    if (auth === false) {
      const check = PRIVATE_ROUTES.some((el): boolean => el.path === location.pathname);
      if (check) {
        navigate('/');
      }
    }
  };
  const chatEnabled = isChatEnabled();

  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, []);

  useEffect((): void => {
    getStyleListMethod().then((res): void => {
      if (res.status === 200) {
        const data = res.data.data;
        appendTheme(data);
        dispatch(setLogoCompany(data.logo_src));
        dispatch(setSignUpImage(data.sign_up));
        dispatch(setSignInImage(data.sign_in));
        dispatch(setForgotPasswordImage(data.reset_password));
      }
    });
  }, []);

  const fetchKashaPayments = (): void => {
    currencyKashaPayments()
      .then((res) => {
        if (res.status === 200) {
          const data = {
            PAYMENT_CURRENCY: res.data.PAYMENT_CURRENCY.map((item) => {
              return { value: item, label: item };
            }),
          };
          dispatch(setPaymentsKashaCurrency(data));
        }
      })
      .catch((error) => console.log(error));
  };

  const fetchPayments = (): void => {
    getPaymentsInfo()
      .then((res) => {
        if (res.status === 200) {
          const data = {
            ENABLE_PAYMENT: Number(res.data.ENABLE_PAYMENT),
            ENABLE_CREDIT_CARD: Number(res.data.ENABLE_CREDIT_CARD),
            ENABLE_WEB_TRADER: Number(res.data.ENABLE_WEB_TRADER),
            ENABLE_CREDIT_CARD_WITHDRAW: res.data.ENABLE_CREDIT_CARD_WITHDRAW,
            PAYMENT_CURRENCY: res.data.PAYMENT_CURRECY.map((item) => {
              return { value: item, label: item };
            }),
            PAYMENT_GATEWAYS: res.data.PAYMENT_GATEWAY.map((item) => {
              return { value: item, label: item };
            }),
            TRANSLATE: res.data.TRANSLATE,
            ENABLE_RECAPTCHA: Number(res.data.ENABLE_RECAPTCHA),
            RECAPTCHA_KEY: res.data.RECAPTCHA_KEY,
            WEBTRADER_URL: res.data.WEBTRADER_URL,
          };
          dispatch(setPaymentsParameters(data));
        }
      })
      .catch((error) => console.log(error));
  };

  // const fetchHeaderInfo = (): void => {
  //   getHeaderInfo()
  //     .then((res): void => {
  //       const info = res.data.data;
  //       dispatch(setHeaderInfo(info));
  //     })
  //     .catch((err) => console.log(err));
  // };

  useCheckIpCountry({ isAuth: auth });

  useEffect((): void => {
    checkRoute();
    fetchPayments();

    if (auth) {
      // fetchHeaderInfo();
      fetchKashaPayments();
    }
  }, [auth]);

  useEffect((): void => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <LangWrapper>
      <Routes>
        {auth
          ? [...CURRENT_PRIVATE_ROUTES, ...ROUTES].map((route) => (
              <Route key={route.id} path={route.path} element={route.component} />
            ))
          : ROUTES.map((route) => (
              <Route key={route.id} path={route.path} element={route.component} />
            ))}
        {auth && chatEnabled ? <Route key="CHAT" path="/chat" element={<ChatPage />} /> : null}
      </Routes>
    </LangWrapper>
  );
};

export default App;
